var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "brandStory" }, [
      _c(
        "div",
        { staticClass: "top-section" },
        [
          _c("StoryViewImage", { attrs: { story: _vm.brandStory } }),
          _vm._m(0),
          _c("BrandStoryViewCopy", {
            staticClass: "story",
            attrs: { brandStory: _vm.brandStory },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "bottom-section" }, [
        _c("div", { staticClass: "invisible" }),
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c("BrandStoryAttachments", {
              staticClass: "attachments",
              attrs: { brandStoryId: _vm.id },
            }),
            _c("BrandStoryOptions", {
              staticClass: "options",
              attrs: { brandStoryId: _vm.id },
            }),
            _c("CommentList", { attrs: { brandStoryId: _vm.id } }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "divider-container" }, [
      _c("div", { staticClass: "divider" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }