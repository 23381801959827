var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "areaList" }, [
    _c("span", [
      _vm._v("Business Area" + _vm._s(_vm.areas[1] ? "s" : "") + ":"),
    ]),
    _c(
      "ul",
      _vm._l(_vm.areas, function (area) {
        return _c("li", { key: area.id }, [_vm._v(_vm._s(area.name))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }