var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "commentItem" }, [
    _c(
      "div",
      {
        staticClass: "circleBorderProfileImg",
        class: _vm.engagementLevelClass,
      },
      [
        _c("UserImageThumbnail", {
          class: "imgProfile",
          attrs: { thumbnailAssetId: _vm.comment.user.thumbnailAssetId },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "copy" }, [
      _c(
        "div",
        { staticClass: "username" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { path: "profile", query: { id: _vm.comment.user.id } },
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setProfileViewUser.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.comment.user.namePreferred) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.comment.text))]),
      _c("div", { staticClass: "duration" }, [
        _vm._v("Last edited " + _vm._s(_vm.changeToDays)),
      ]),
    ]),
    _c("div", { staticClass: "duration" }, [_vm._v(_vm._s(_vm.changeToDays))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }