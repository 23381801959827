var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "attachment-item" }, [
    _c(
      "a",
      {
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.processFile.apply(null, arguments)
          },
        },
      },
      [_vm._v("\n    " + _vm._s(_vm.cleanFileName) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }