var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.brandStory
    ? _c("div", { staticClass: "brandStoryViewCopy" }, [
        _c("div", { staticClass: "infoWrapper" }, [
          this.isPostedByUserNotAdmin
            ? _c("div", { staticClass: "userInfo" }, [
                _c("div", { staticClass: "img" }, [
                  _c(
                    "div",
                    { staticClass: "circleBorderProfileImg" },
                    [
                      _c("UserImageThumbnail", {
                        class: "imgProfile",
                        attrs: {
                          thumbnailAssetId:
                            _vm.brandStory.submittedByUser.thumbnailAssetId,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "postInfo" }, [
                  _c(
                    "div",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.copy.postedBy) +
                          "\n          "
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: "profile",
                              query: { id: _vm.brandStory.submittedByUser.id },
                            },
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.setProfileViewUser.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.brandStory.submittedByUser.namePreferred)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", [_vm._v(_vm._s(this.postedDate))]),
                ]),
              ])
            : _c("div", { staticClass: "postDate" }, [
                _vm._v(_vm._s(this.postedDate)),
              ]),
          _c(
            "div",
            { staticClass: "categories" },
            [
              _c("EffortlessTenetList", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.brandStory.effortlessTenets.length > 0,
                    expression: "brandStory.effortlessTenets.length > 0",
                  },
                ],
                staticClass: "tenets",
                attrs: { tenets: _vm.brandStory.effortlessTenets },
              }),
              _c("BusinessAreaList", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.brandStory.businessAreas.length > 0,
                    expression: "brandStory.businessAreas.length > 0",
                  },
                ],
                staticClass: "businessAreas",
                attrs: { areas: _vm.brandStory.businessAreas },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.brandStory.subject)),
        ]),
        _c("div", {
          directives: [
            { name: "openLinksInNewTab", rawName: "v-openLinksInNewTab" },
          ],
          domProps: { innerHTML: _vm._s(_vm.brandStory.body) },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }